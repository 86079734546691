html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Object Sans;
  src: url("PPObjectSans-Light.55cd577c.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Object Sans;
  src: url("PPObjectSans-Regular.8b856173.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Object Sans;
  src: url("PPObjectSans-Bold.6e7a83cb.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Object Sans;
  src: url("PPObjectSans-Heavy.4aad05e5.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Object Sans;
  src: url("PPObjectSans-HeavySlanted.c08053ac.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: NB Architekt;
  src: url("NB-Architekt-Neue-Normal.d6ca6717.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NB Architekt;
  src: url("NB-Architekt-Neue-Medium.bb71e5fe.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root {
  --n100: #e7e7e6;
  --n200: #cfcecd;
  --n300: #b6b6b4;
  --n400: #9e9d9b;
  --n500: #868581;
  --n600: #6e6c68;
  --n700: #56544f;
  --n800: #3d3b36;
  --n900: #25231d;
  --n1000: #0d0a04;
  --y100: #fffcf6;
  --y200: #fffaee;
  --y300: #fff3d4;
  --y400: #ffe6aa;
  --y500: #ffda7f;
  --y600: #ffd56e;
  --y700: #ffcd54;
  --y800: #d99f2b;
  --y900: #ae7f22;
  --y1000: #684c14;
  --o100: #fff7f5;
  --o200: #fff0eb;
  --o300: #ffd9cc;
  --o400: #ffb39a;
  --o500: #ff8e67;
  --o600: #ff7f53;
  --o700: #ff6835;
  --o800: #de5a2e;
  --o900: #cc5229;
  --o1000: #80341a;
  --g100: #f8fae5;
  --g200: #fafcee;
  --g300: #f3f7d3;
  --g400: #e8efa8;
  --g500: #dce77c;
  --g600: #d8e46b;
  --g700: #d1df51;
  --g800: #b2be45;
  --g900: #9da73d;
  --g1000: #687028;
  --text-color-primary: var(--n1000);
  --text-color-secondary: var(--n700);
  --text-color-inactive: var(--n600);
  --text-size-1: 1rem;
  --text-size-2: 1.2rem;
  --text-size-3: 1.4rem;
  --text-size-4: 1.6rem;
  --text-size-5: 2rem;
  --text-size-6: 2.8rem;
  --text-size-7: 3.2rem;
  --text-weight-light: 300;
  --text-weight-regular: 400;
  --text-weight-bold: 700;
  --button-primary-active: var(--y700);
  --button-primary-hover: var(--y800);
  --button-primary-pressed: var(--y900);
  --button-primary-inactive: var(--n200);
  --button-primary-text: var(--text-primary);
  font-size: 10px;
}

[data-theme="dark"] {
  --text-primary: var(--n100);
  --text-secondary: var(--n200);
  --text-inactive: var(--n300);
  --button-primary-active: var(--y700);
  --button-primary-hover: var(--y600);
  --button-primary-pressed: var(--y100);
  --button-primary-inactive: var(--n700);
  --button-primary-text: var(--text-primary);
}

* {
  scrollbar-width: auto;
  scrollbar-color: #000 #fff;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border: 5px solid #fff;
  border-radius: 0;
}

html {
  font-style: normal;
}

.announcements {
  background: var(--n1000);
  color: #fff;
  scrollbar-color: #3d3b36 #0d0a04;
  grid-row: 1 / 3;
  overflow-y: scroll;
}

.announcements::-webkit-scrollbar {
  width: 16px;
}

.announcements::-webkit-scrollbar-track {
  background: #0d0a04;
}

.announcements::-webkit-scrollbar-thumb {
  background-color: #3d3b36;
  border: 5px solid #0d0a04;
  border-radius: 0;
}

.announcements > div > div {
  background: var(--n1000);
  padding-bottom: 8px !important;
}

.announcements .grid-wrapper, .resources .grid-wrapper {
  padding-top: 0;
}

.announcements > div > div, .resources .wrap {
  width: 100%;
  padding: 24px 0;
  position: sticky;
  top: 0;
}

.announcements .metadata {
  text-transform: uppercase;
  font-weight: var(--text-weight-light);
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
}

.announcements .metadata p:after {
  content: "·";
  font-weight: var(--text-weight-light);
  margin-left: 8px;
  display: inline-block;
}

.announcements .metadata #iotd_title {
  font-weight: var(--text-weight-bold);
}

.announcements .metadata p:last-child:after {
  display: none;
}

.announcements .metadata img {
  border-radius: 50%;
  width: 24px;
}

.announcements li {
  border-bottom: 1px solid var(--n800);
  padding: 24px 0;
}

.bold {
  font-weight: var(--text-weight-bold);
}

body {
  font-family: Object Sans, Helvetica, sans-serif;
  font-weight: var(--text-weight-regular);
  align-items: center;
  height: 100vh;
  font-style: normal;
  display: flex;
  overflow-y: hidden;
}

body.login {
  background: var(--y700);
  justify-content: center;
  margin: 0;
}

body.home, body.home nav {
  background: var(--n1000);
}

body.home nav .events {
  background-image: url("calendar-white.2e04f9ac.svg");
}

body.home nav .logout {
  background-image: url("logout-white.799e0548.svg");
}

body.home nav .office {
  background-image: url("office-white.b0c9ee48.svg");
}

body.home nav .myspace {
  background-image: url("user-white.8a924378.svg");
}

body.home nav .funfive {
  background-image: url("hand-white.92f24c98.svg");
}

body.home nav .wheel {
  background-image: url("wheel-white.b67e5e4f.svg");
}

body.home nav .mobile a {
  color: var(--n500);
}

body.home nav .mobile .selected {
  color: var(--n100);
}

.brush {
  position: absolute;
}

.brush.top-left {
  top: -8px;
  left: -48px;
  rotate: -24.87deg;
}

.brush.bottom-right {
  bottom: 0;
  right: -64px;
  rotate: -29.91deg;
}

button {
  cursor: pointer;
  color: var(--n1000);
  background: var(--y700);
  font-size: var(--text-size-3);
  font-weight: var(--text-weight-bold);
  border: none;
  border-radius: 80px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 40px;
  font-family: Object Sans, Helvetica, sans-serif;
  display: inline-block;
}

button.outline {
  border: 2px solid var(--n1000);
  font-weight: var(--text-weight-regular);
  background: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 14px 24px;
  display: flex;
}

button.outline img {
  display: none;
}

button.outline:hover {
  background: var(--n100);
}

button.outline.selected {
  background: var(--n200);
  padding: 4px 24px 4px 16px;
}

button.outline.selected img {
  display: block;
}

.card {
  background: var(--g700);
  grid-template-columns: 1fr 1fr;
  width: 40vw;
  height: 50vh;
  display: grid;
}

.card.countdown {
  background: var(--n1000);
  color: #fff;
  width: 55vw;
  height: 100%;
}

.card.countdown h2 {
  color: var(--y700);
}

.card.countdown .card-details {
  grid-template-rows: 1fr auto;
  gap: 24px;
  padding: 0;
  display: grid;
}

.clicker {
  background-color: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.countdown-info {
  padding: 24px;
}

.card.event {
  max-height: 500px;
}

.card.event:nth-of-type(odd) {
  margin-top: 100px;
}

.card.event:nth-of-type(2n) {
  background: var(--o700);
  margin-top: -100px;
}

.card-details {
  font-size: var(--text-size-4);
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

.card-details .metadata {
  flex-direction: column;
  row-gap: 4px;
  display: flex;
}

.card-image {
  background-image: url("https://uploads-ssl.webflow.com/63483b0e2f4d8b3e51c2569b/6348484ea246c655cc17d687_Event%20Image.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  flex-flow: row;
  align-items: center;
  height: 80%;
  margin-left: 12vw;
  display: flex;
  position: relative;
}

.empty-container {
  font-size: var(--text-size-5);
  color: var(--text-color-secondary);
  text-align: center;
  flex-direction: column;
  grid-area: 3 / 1 / 5 / 2;
  align-items: center;
  gap: 40px;
  padding: 40px 0;
  line-height: 160%;
  display: none;
}

.not100vh {
  height: auto;
  display: block;
  overflow-y: auto;
}

.funfive-wrapper {
  font-size: var(--text-size-4);
  width: calc(100% - 12vw - 64px);
  height: auto;
  margin-top: 48px;
  margin-left: calc(12vw + 40px);
  display: block;
}

.funfive-wrapper .flex-wrapper {
  grid-template-rows: min-content min-content min-content 1fr;
  grid-template-columns: 60% auto;
  gap: 24px;
  display: grid;
}

.funfive-wrapper .lines {
  grid-area: 1 / 1 / 2 / 2;
}

#feed {
  grid-area: 3 / 1 / 5 / 2;
  margin-bottom: 80px;
}

.new-fives {
  grid-area: 1 / 2 / 4 / 3;
}

.new-fives-confirm {
  background-color: var(--g700);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  flex-direction: column;
  grid-area: 1 / 2 / 4 / 3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.new-fives-confirm .hands {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
}

.new-fives-confirm .hand {
  width: 50%;
  max-width: 200px;
}

#hand-1 {
  z-index: 2;
}

.new-fives-confirm #smack {
  z-index: 1;
  width: 100px;
  margin-top: 80px;
  margin-bottom: -25px;
}

#copy {
  z-index: 2;
}

.new-fives-confirm .clouds {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-clouds, .bottom-clouds {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.top-clouds {
  margin-top: 40px;
}

.bottom-clouds {
  margin-top: 100px;
}

#topleft {
  width: 20%;
  max-width: 120px;
}

#topright, #bottomleft {
  width: 40%;
  max-width: 200px;
}

#topright {
  max-width: 160px;
  margin-top: 20px;
}

#bottomright {
  z-index: 2;
  width: 25%;
  max-width: 160px;
}

.funfive-wrapper h1 {
  font-size: var(--text-size-6);
  font-weight: var(--text-weight-bold);
  text-transform: none;
  margin-bottom: 24px;
  position: static;
}

.funfive-wrapper .lines {
  filter: invert();
  width: 100%;
  margin-top: 0;
}

#funfive-quill {
  border: 2px solid var(--n1000);
  box-sizing: border-box;
  resize: vertical;
  font-family: Object Sans, Helvetica, sans-serif;
  font-weight: var(--text-weight-regular);
  font-style: normal;
  font-size: var(--text-size-3);
  background-color: #0000;
  border-radius: 4px;
  width: 100%;
  margin: 24px 0 0;
  display: block;
}

.mention-container {
  border: 2px solid var(--n1000);
  background-color: #fff;
  border-radius: 4px;
  max-height: 250px;
  padding: 20px;
  overflow: scroll;
}

.mention-list-item {
  cursor: pointer;
  margin-bottom: 20px;
}

.filters {
  grid-area: 2 / 1 / 3 / 2;
  gap: 16px;
  display: flex;
}

#feed .date-block {
  padding: 16px 0 0;
}

#feed .date-block.last {
  border-bottom: 1px solid var(--n300);
  padding-bottom: 32px;
}

#feed .send-date {
  text-align: center;
  font-size: var(--text-size-3);
  color: var(--text-color-secondary);
  text-transform: uppercase;
  width: 100%;
  margin-top: 8px;
  display: inline-block;
}

#feed li {
  margin-top: 32px;
}

#feed li:first-of-type {
  margin-top: 16px;
}

#feed li .metadata {
  margin-bottom: 10px;
}

#feed #message {
  line-height: 160%;
}

#feed li span {
  font-weight: var(--text-weight-bold);
}

.facestack {
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 20px;
  display: inline-flex;
}

.facestacksub {
  flex-wrap: wrap;
  width: 100%;
  min-width: 52px;
  height: 100%;
  margin-left: -16px;
  display: flex;
}

.facestack img {
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 52px !important;
  height: 52px !important;
}

.facestack .facestacksub img {
  margin-left: -16px;
}

.ff-image {
  z-index: 1;
  opacity: 0;
  transform-origin: center;
  margin-left: -16px;
  margin-right: -16px;
  border: none !important;
  transform: scale(.8) !important;
}

.new-fives {
  background-color: var(--g700);
  flex-flow: column;
  padding: 40px;
  display: flex;
  position: sticky;
  top: 24px;
}

.new-fives label:first-child {
  font-size: var(--text-size-6);
}

.new-fives label:nth-of-type(2) {
  font-size: var(--text-size-3);
  font-weight: var(--text-weight-bold);
  margin-bottom: 8px;
}

.new-fives textarea {
  border: 2px solid var(--n1000);
  box-sizing: border-box;
  resize: vertical;
  font-family: Object Sans, Helvetica, sans-serif;
  font-weight: var(--text-weight-regular);
  font-style: normal;
  font-size: var(--text-size-3);
  background-color: #0000;
  border-radius: 4px;
  width: 100%;
  margin: 24px 0 0;
  padding: 16px;
  display: block;
}

.new-fives textarea::placeholder {
  color: var(--text-color-secondary);
}

select {
  color: var(--text-color-primary);
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid var(--n1000);
  font-family: Object Sans, Helvetica, sans-serif;
  font-weight: var(--text-weight-regular);
  font-style: normal;
  font-size: var(--text-size-3);
  background: url("chevron-down.a3b52925.svg") calc(100% - 16px) / 24px no-repeat;
  border-radius: 4px;
  padding: 12px 16px;
  display: block;
}

option {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10px;
  overflow: hidden;
}

.filters select {
  flex-grow: 1;
}

.new-fives button {
  background-color: var(--n1000);
  color: #fff;
  width: 100%;
  margin-top: 24px;
}

.new-fives button:hover {
  background: var(--n900);
}

.event-auth {
  text-align: center;
  font-size: var(--text-size-3);
  flex-direction: column;
  place-content: center;
  width: 85%;
  margin: 0 auto;
  line-height: 130%;
  display: flex;
}

.event-auth button {
  margin-top: 40px;
}

.event-auth .close {
  background: #fff;
  padding: 12px 12px 8px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.event-auth .logo {
  width: 160px;
  margin-bottom: 20px;
}

.event-auth main {
  background: #fff;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  padding: 40px;
  display: flex;
}

.event-auth h1 {
  font-size: var(--text-size-4);
  font-weight: var(--text-weight-bold);
  text-transform: none;
  z-index: 0;
  margin-bottom: 16px;
  display: inline-block;
  position: static;
}

.events-wrap {
  column-gap: 24px;
  max-height: 800px;
  padding-right: 12vw;
}

.fixed-wrapper {
  position: absolute;
  top: 0;
}

.funsizer, .funsizer > div {
  background: var(--y700);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

.funsizer .info {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.funsizer .name {
  font-size: var(--text-size-5);
  font-weight: var(--text-weight-bold);
  margin-bottom: 6px;
}

.funsizer .wrap {
  color: var(--n1000);
  font-size: var(--text-size-3);
  font-weight: var(--text-weight-bold);
  background: #fff;
  border-radius: 80px;
  padding: 16px 24px;
  text-decoration: none;
}

.funsizer a:hover {
  background: var(--n100);
}

.funsizer .wrap img {
  height: 20px;
}

.funsizer .wrap p {
  margin-bottom: -2px;
}

.funsizer .year {
  font-weight: var(--text-weight-bold);
}

.grid-title {
  font-size: var(--text-size-5);
}

.grid-wrapper {
  padding: 24px 8px 24px 24px;
  position: relative;
}

h1 {
  font-size: var(--text-size-7);
  text-transform: uppercase;
  z-index: -1;
  position: fixed;
}

h2 {
  font-size: var(--text-size-7);
  text-transform: uppercase;
  margin-bottom: 24px;
  display: inline-block;
}

.hidden {
  opacity: 0;
}

.home .content {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 12vw;
}

.home .metadata {
  text-align: center;
  max-width: 85%;
}

.home .metadata #iotd_subtitle {
  font-size: var(--text-size-3);
  font-weight: var(--text-weight-light);
}

.home .metadata #iotd_title {
  font-size: var(--text-size-4);
  font-weight: var(--text-weight-bold);
  text-align: center;
  column-gap: 4px;
  margin: 8px 0;
}

.home .metadata .wrap img {
  height: 20px;
}

.info {
  padding: 0 24px;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  color: var(--n1000);
  font-family: Object Sans, Helvetica, sans-serif;
  font-size: var(--text-size-5);
  font-weight: var(--text-weight-regular);
  caret-color: var(--y700);
  background: none;
  border: none;
  width: 100%;
}

input[type="search"]:focus-visible {
  outline: none;
}

input[type="search"]::placeholder {
  color: var(--n800);
  opacity: .5;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.lines {
  background: url("lines.e4a02997.png");
  height: 8px;
  margin-top: 24px;
}

.loader-container {
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-container-inline {
  display: none;
  position: relative;
}

.loader {
  background-color: var(--n1000);
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.light {
  background-color: #fff;
}

.logo {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.logo img {
  width: auto;
  max-width: calc(100% - 16px);
  height: 32px;
}

.login main {
  background: var(--n1000);
  font-size: var(--text-size-4);
  grid-template-rows: auto;
  grid-template-columns: minmax(0, 55%) minmax(0, 1fr);
  width: calc(100vw - 200px);
  height: calc(100vh - 200px);
  line-height: 120%;
  display: grid;
}

.login main > div:first-child {
  background: url("login-fallback.90adce8a.png") 50% / cover;
}

.map {
  border-top: 2px solid var(--n1000);
  border-bottom: 2px solid var(--n1000);
  grid-area: 2 / 1 / 3 / 3;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 45% 1fr;
  place-items: center;
  display: grid;
  height: calc(100% - 4px) !important;
}

.map img {
  justify-self: flex-end;
  width: calc(95% - 40px);
  max-height: calc(95% - 40px);
  margin-right: 40px;
}

.map ul {
  width: 85%;
}

.map li {
  border-bottom: 1px solid var(--n1000);
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 8px;
  display: flex;
}

.map li:first-child {
  padding-top: 10px;
}

.map li p:nth-child(2) {
  font-weight: var(--text-weight-bold);
}

.modal-wrapper {
  z-index: 999;
  background-color: #ffffffbf;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  font-size: var(--text-size-4);
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  gap: 8px;
  width: 45%;
  padding: 40px;
  line-height: 160%;
  display: flex;
  position: relative;
  box-shadow: 0 26px 80px #0000001a, 0 13.1625px 34.875px #0001, 0 5.2px 13px #0000000d, 0 1.1375px 4.625px #00000008;
}

.modal-close {
  place-items: center;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  top: 14px;
  right: 14px;
}

.modal-close img {
  width: 24px;
  height: 24px;
}

.modal-open {
  display: flex;
}

.myspace-wrapper {
  font-size: var(--text-size-3);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  margin-right: 6vw;
  display: grid;
}

.myspace-wrapper > div {
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
}

.myspace-user-info {
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  display: flex;
}

.myspace-user-info:hover {
  background-color: var(--y400) !important;
}

#myspace-user-edit {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  display: flex;
}

#myspace-user-edit button {
  margin-top: 16px;
}

#myspace-user-edit input[type="text"] {
  -webkit-appearance: none;
  appearance: none;
  border: solid 1px var(--y700);
  color: var(--n1000);
  font-family: Object Sans, Helvetica, sans-serif;
  font-size: var(--text-size-4);
  font-weight: var(--text-weight-regular);
  caret-color: var(--y700);
  text-align: center;
  background: none;
  width: 100%;
  padding: 8px;
}

#myspace-current-image {
  border: solid 1px var(--y700);
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 56px;
  width: 112px;
  height: 112px;
}

.MMFOOD, .mobile-close {
  display: none;
}

nav {
  z-index: 1;
  background: #fff;
  flex-direction: column;
  place-content: center space-between;
  align-items: center;
  width: 12vw;
  height: calc(100vh - 96px);
  display: flex;
  position: fixed;
}

nav ul {
  flex-direction: column;
  gap: 20px;
  padding: 50px;
  display: flex;
}

nav ul li a {
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: inline-block;
}

nav ul li a:hover {
  border: 1px solid var(--n200);
}

body.home nav ul li a:hover {
  border-color: var(--n500);
}

nav .selected {
  background-color: var(--y700);
}

nav .selected:hover {
  background-color: var(--y800);
  border: none;
}

nav .home {
  background-image: url("home.f3e86532.svg");
}

nav .events {
  background-image: url("calendar.e921abfa.svg");
}

nav .funfive {
  background-image: url("hand.dca2aed3.svg");
}

nav .wheel {
  background-image: url("wheel.3e21c213.svg");
}

nav .logout {
  background-image: url("logout.c4128bfe.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
  width: 44px;
  height: 44px;
}

nav .mobile {
  display: none;
}

nav .office {
  background-image: url("office.13d928f1.svg");
}

nav .myspace {
  background-image: url("user.4869d5af.svg");
}

nav ul img {
  width: 28px;
  height: 28px;
}

nav #webgl {
  pointer-events: none;
  z-index: -100;
  display: none;
}

.profile-photo {
  object-fit: cover;
  border-radius: 50%;
  width: 96px !important;
  height: 96px !important;
}

.react-block {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
  display: flex;
}

.react-new {
  background: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.react-new img {
  pointer-events: none;
}

.react-new:hover img {
  filter: brightness(.6);
}

.react-pill {
  font-size: var(--text-size-3);
  font-weight: var(--text-weight-regular);
  background: var(--n100);
  flex-direction: row;
  align-items: center;
  gap: 7px;
  padding: 7px 12px 7px 11px;
  display: flex;
  position: relative;
}

.react-pill.selected {
  color: #fff;
  background: #1297fc;
}

.react-pill span.emoji {
  font-size: var(--text-size-4);
}

.react-pill span, p {
  pointer-events: none;
}

.react-pill:hover {
  background: var(--n200);
}

.react-pill.selected:hover {
  background: #0874c6;
}

.react-pill:hover .react-list {
  display: inline;
}

.react-pill img {
  width: 16px;
  height: 16px;
}

.react-list {
  color: #fff;
  z-index: 2;
  background: #000;
  border-radius: 4px;
  width: 120px;
  margin-bottom: 8px;
  padding: 8px;
  display: none;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.react-list:before {
  content: "";
  border: 6px solid #0000;
  border-top-color: #000;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

#reaction-menu {
  z-index: 4;
  border: 2px solid var(--n100);
  background: #fff;
  border-radius: 6px;
  flex-direction: row;
  padding: 6px;
  display: flex;
  position: absolute;
  top: 0;
}

#reaction-menu button {
  box-sizing: border-box;
  font-size: var(--text-size-5);
  background: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
}

#reaction-menu button:hover {
  background: var(--n100);
}

#reaction-menu img {
  width: 24px;
  height: 24px;
}

.resources {
  background: var(--o700);
  position: relative;
  overflow-y: scroll;
}

.resources .fixed-wrapper {
  width: calc(100% - 48px);
}

.resources ul li {
  border-bottom: 1px solid var(--o900);
}

.resources ul li a {
  color: var(--text-primary);
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 8px;
  line-height: 130%;
  text-decoration: none;
  display: flex;
}

.resources ul li a img {
  opacity: 0;
  padding-right: 4px;
}

.resources ul li a:hover {
  background: var(--o800);
}

.resources ul li a:hover img {
  opacity: 1;
}

.resources ul li a:active {
  background: var(--o900);
}

.resources .wrap {
  background: var(--o700);
  justify-content: space-between;
  display: flex;
}

.resources {
  scrollbar-width: auto;
  scrollbar-color: #cc5229 #ff6835;
}

.resources::-webkit-scrollbar {
  width: 16px;
}

.resources::-webkit-scrollbar-track {
  background: #ff6835;
}

.resources::-webkit-scrollbar-thumb {
  background-color: #cc5229;
  border: 5px solid #ff6835;
  border-radius: 0;
}

.search-wrapper {
  flex-direction: row;
  align-items: center;
  margin: 24px 0;
  display: none;
}

.search-wrapper img {
  width: 32px;
}

.slack-message {
  letter-spacing: .1px;
  color: var(--n400);
  margin-top: 16px;
  line-height: 130%;
}

.slack-message a {
  color: var(--y700);
  text-decoration: none;
}

.slack-message .slack-cmd {
  color: var(--g700);
}

.soon section {
  font-size: var(--text-size-5);
  text-transform: uppercase;
  font-weight: var(--text-weight-bold);
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.soon div:not(.soon main div) {
  justify-content: center;
  align-items: center;
  width: 325px;
  height: 150px;
  display: flex;
  position: relative;
}

.soon #top p {
  margin-top: -40px;
}

.soon #bottom p {
  margin-top: 40px;
  font-style: italic;
  font-weight: 570;
}

.soon div img {
  z-index: -1;
  position: absolute;
}

.soon main {
  gap: 24px;
  display: flex;
}

.soon main div {
  flex-direction: column;
  align-items: center;
  width: 225px;
  display: flex;
}

.soon main p {
  letter-spacing: -4px;
  color: #fff;
  -webkit-text-stroke: 4px var(--n1000);
  text-shadow: 4px 12px 0px var(--n1000);
  font-size: 16rem;
  font-weight: var(--text-weight-regular);
}

.soon main span {
  font-size: var(--text-size-3);
  background: var(--y700);
  border-radius: 20px;
  padding: 8px 12px;
}

sup {
  vertical-align: super;
  font-size: var(--text-size-3);
  margin-left: 6px;
  font-style: normal;
}

.splash-wrapper {
  max-height: 80%;
  margin: 32px 64px;
  position: relative;
}

.splash-image {
  max-height: 100%;
  display: block;
}

.thanos {
  display: none !important;
}

.unkown-person-container {
  width: 100%;
  height: 100%;
}

#unknown-person {
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 48px 56px;
  display: flex;
}

#unknown-person .logo {
  mix-blend-mode: exclusion;
  width: 60%;
  margin-bottom: 32px;
}

#unknown-person p {
  max-width: 360px;
}

.wrap {
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  display: flex;
}

.wrap p {
  margin-bottom: -4px;
}

#webgl {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.webgl-card {
  position: relative !important;
}

.webgl-container {
  opacity: 0;
  perspective: 500px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.webgl-event {
  z-index: -100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.webgl-svg {
  width: 110%;
  height: 110%;
  position: absolute;
}

.webgl-svg-large {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.4);
}

.webgl-svg-rel {
  position: relative;
}

.year {
  margin-top: 2px;
}

::selection {
  background: var(--y700);
  color: var(--n1000);
}

::selection {
  background: var(--y700);
  color: var(--n1000);
}

.integration {
  color: #111;
  border: 10px solid #fff;
  margin: 20px;
  padding: 20px;
}

#g_id_signin {
  margin-top: 48px;
}

.links {
  margin-top: 20px;
}

#data_links {
  flex-direction: column;
  display: flex;
}

#data_iotd_img {
  width: 250px;
}

.int_title {
  margin-top: 20px;
  font-weight: 600;
}

.int_message {
  margin-top: 10px;
}

.admin_head {
  color: #fff;
  z-index: 1;
  background-color: #111;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-size: large;
  display: flex;
  position: fixed;
}

.admin_head:first-child {
  margin-left: 20px;
}

.admin_head div:last-child {
  margin-right: 20px;
}

.int_btn {
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  margin-right: 5px;
  padding: 5px;
}

.int_btn:hover {
  color: #111;
  background-color: #fff;
}

.int_selected {
  pointer-events: none;
  border: none;
  font-weight: 600;
}

.int_btn:first-child {
  margin-left: 10px;
}

.int_admin_body {
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.int_admin_body select, .int_admin_body option {
  all: revert;
}

#admin_data {
  flex-flow: wrap;
  margin: 50px 0 0 10px;
  display: flex;
}

.peep {
  cursor: pointer;
  border: 1px solid #111;
  flex-direction: column;
  margin: 10px 10px 0 0;
  padding: 25px;
  display: flex;
  position: relative;
}

.peep img {
  pointer-events: none;
  width: 150px;
  margin-bottom: 10px;
}

.peep span {
  pointer-events: none;
}

.spot {
  border: 1px solid #111;
  flex-direction: column;
  margin: 10px 10px 0 0;
  padding: 25px;
  display: flex;
  position: relative;
}

.spot_hot {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.int_data_edit {
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.int_data_edit_btns {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  display: flex;
}

.int_hidden {
  display: none;
}

.int_modal_shim {
  z-index: 2;
  background-color: #0004;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
}

.int_modal {
  background-color: #fff;
  border: 1px solid #111;
  flex-direction: column;
  margin: 25px;
  padding: 25px;
  display: flex;
}

@media (min--moz-device-pixel-ratio: 0) {
  .splash-wrapper {
    max-width: 80vh;
  }

  .grid-wrapper {
    padding-right: 24px;
  }
}

@media screen and (min-width: 2000px) {
  .myspace-wrapper {
    gap: 60px 48px;
  }
}

@media screen and (min-width: 1400px) {
  .announcements > div > div {
    padding: 40px 0 16px !important;
  }

  .card {
    max-width: 700px;
    max-height: 40vh;
  }

  .card.countdown {
    max-width: 1000px;
    max-height: max-content;
  }

  .funsizer .name {
    font-size: var(--text-size-6);
  }

  .funsizer .wrap :not(.funsizer a p) {
    font-size: var(--text-size-4);
  }

  .grid-title {
    font-size: var(--text-size-6);
  }

  .grid-wrapper {
    padding: 40px 24px 40px 40px;
  }

  .info {
    padding: 0 40px;
    line-height: 130%;
  }

  input[type="search"] {
    font-size: var(--text-size-6);
  }

  .myspace-wrapper {
    font-size: var(--text-size-4);
  }

  .resources ul li a {
    padding: 24px 0 24px 8px;
  }

  .resources .wrap {
    padding: 40px 0 24px;
  }

  .search-wrapper {
    margin: 40px 0 24px;
  }

  .slack-message {
    line-height: 160%;
  }
}

@media screen and (max-width: 1200px) {
  .login main {
    grid-template-columns: minmax(0, 45%) minmax(0, 1fr);
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
  }

  .map {
    grid-template-columns: 80%;
    justify-content: center;
  }

  .map img {
    display: none;
  }

  .metadata p:nth-child(2):after {
    content: "";
  }

  .metadata p:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: 1070px) {
  .filters {
    flex-flow: wrap;
  }

  .filters select {
    width: 100%;
  }

  .filters button {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1030px) {
  .soon main p {
    text-shadow: 4px 8px 0px var(--n1000);
    font-size: 12rem;
  }
}

@media screen and (max-width: 940px) {
  .funfive-wrapper .flex-wrapper {
    grid-template-columns: 50% auto;
  }

  .card {
    width: 50vw;
  }

  .card.countdown {
    width: 70vw;
  }

  .card.event:nth-of-type(odd) {
    margin-top: -7vh;
  }

  .card.event:nth-of-type(2n) {
    margin-top: 7vh;
  }
}

@media screen and (max-width: 800px) {
  .funfive-wrapper .flex-wrapper {
    grid-template-columns: 1fr;
    gap: 36px;
  }

  .filters {
    grid-row: 3 / 4;
  }

  .new-fives, .new-fives-confirm {
    grid-area: 2 / 1 / 3 / 2;
    position: static;
  }

  .new-fives-confirm {
    position: relative;
  }

  #feed, .empty-container {
    grid-row: 4 / 5;
  }
}

@media screen and (max-width: 770px) {
  .soon {
    font-size: var(--text-size-4);
  }

  .soon div:not(.soon main div) {
    width: 250px;
    height: 110px;
  }

  .soon img {
    width: 250px;
  }

  .soon main p {
    text-shadow: 4px 4px 0px var(--n1000);
    font-size: 10rem;
  }

  .soon #top p {
    margin-top: -25px;
  }

  .soon #bottom p {
    margin-top: 25px;
  }
}

@media screen and (max-width: 650px) {
  .announcements {
    grid-row-start: 2;
  }

  .big-number {
    padding-top: 40px;
  }

  .brush {
    scale: 80%;
  }

  body:not(.event-auth, .login) {
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow-y: auto;
  }

  .content {
    height: inherit;
    flex-direction: column;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .content.myspace-wrapper {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin: 94px auto 12vh;
  }

  .card {
    grid-template-columns: 1fr;
    width: 100%;
    min-height: 40vh;
  }

  .card-details {
    gap: 24px;
  }

  .card.countdown {
    width: 100%;
  }

  .card-image {
    display: none;
  }

  .card.event:nth-of-type(odd), .card.event:nth-of-type(2n) {
    margin-top: initial;
  }

  .events-wrap {
    padding-right: initial;
    row-gap: 24px;
    max-height: min-content;
    margin-top: 94px;
    margin-bottom: 12vh;
  }

  .fixed-wrapper {
    position: initial;
    width: 100%;
  }

  .funsizer .profile-photo {
    margin-top: 24px;
    width: 112px !important;
    height: 112px !important;
  }

  .funsizer > div {
    margin: 24px;
  }

  .funfive-wrapper {
    margin-top: 0;
  }

  .funfive-wrapper h1 {
    padding-top: 120px;
  }

  .funfive-wrapper .new-fives-confirm h1 {
    position: absolute;
    bottom: 30px;
  }

  h1 {
    position: initial;
  }

  .home .content {
    margin-right: inherit;
    height: auto;
    padding-top: calc(94px + 8vh);
  }

  .login main {
    grid-template-rows: minmax(0, 35%) minmax(0, 1fr);
    grid-template-columns: auto;
    width: 100vw;
    height: 100vh;
  }

  .map {
    grid-area: auto;
    grid-template-columns: 1fr;
  }

  .map ul {
    width: 100%;
    margin: 24px auto;
  }

  .map li:last-child {
    border-bottom: none;
    padding-bottom: 8px;
  }

  .MMFOOD {
    cursor: pointer;
    padding: 10px;
    display: inline-block;
  }

  .mobile-menu {
    flex-direction: column;
    place-content: center;
    gap: 40px;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: fixed;
  }

  .mobile-menu ul {
    gap: 40px;
    padding: 0;
    display: flex;
  }

  .mobile-menu li a, .mobile-menu .logout {
    width: 64px;
    height: 64px;
  }

  .mobile-menu .logo, .mobile-menu .MMFOOD {
    display: none;
  }

  .mobile-menu #btn_logout, .mobile-menu .mobile-close {
    display: inline-block;
  }

  .mobile-menu .mobile-close {
    width: 24px;
    height: 24px;
    padding: 10px;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .mobile-menu .home:not(.selected) {
    background-image: url("home-white.d36a48c5.svg");
  }

  .mobile-menu .events:not(.selected) {
    background-image: url("calendar-white.2e04f9ac.svg");
  }

  .mobile-menu .myspace:not(.selected) {
    background-image: url("user-white.8a924378.svg");
  }

  .mobile-menu .funfive:not(.selected) {
    background-image: url("hand-white.92f24c98.svg");
  }

  .mobile-menu .wheel:not(.selected) {
    background-image: url("wheel-white.b67e5e4f.svg");
  }

  .mobile-menu .logout {
    background-image: url("logout-white.799e0548.svg");
  }

  nav {
    flex-direction: row;
    align-self: flex-end;
    width: calc(100% - 40px);
    height: auto;
    margin-right: 16px;
    padding: 24px 0;
  }

  nav .logo {
    width: 80px;
  }

  nav ul, #btn_logout {
    display: none;
  }

  nav #webgl {
    display: block;
  }

  .not100vh {
    display: flex;
  }

  .logo img {
    max-width: 100%;
  }

  .soon {
    height: 100vh !important;
    overflow-y: hidden !important;
  }

  .soon main {
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    width: 80%;
    display: grid;
  }

  .soon main div {
    width: 100%;
  }

  #unknown-person {
    margin-bottom: 80px;
  }
}

@media screen and (max-height: 900px) {
  .profile-photo {
    width: 80px;
  }
}

@media screen and (max-height: 650px) {
  .profile-photo {
    display: none;
  }
}

/*# sourceMappingURL=pattern.41e4058b.css.map */
