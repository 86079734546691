@font-face {
    font-family: 'Object Sans';
    src: url(../font/PPObjectSans-Light.otf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans';
    src: url(../font/PPObjectSans-Regular.otf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans';
    src: url(../font/PPObjectSans-Bold.otf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans';
    src: url(../font/PPObjectSans-Heavy.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans';
    src: url(../font/PPObjectSans-HeavySlanted.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}


@font-face {
    font-family: 'NB Architekt';
    src: url(../font/NB-Architekt-Neue-Normal.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NB Architekt';
    src: url(../font/NB-Architekt-Neue-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

:root {
    /* colors */
        /* neutral */
        --n100: #E7E7E6;
        --n200: #CFCECD;
        --n300: #B6B6B4;
        --n400: #9E9D9B;
        --n500: #868581;
        --n600: #6E6C68;
        --n700: #56544F;
        --n800: #3D3B36;
        --n900: #25231D;
        --n1000: #0D0A04;

        /* yellow */
        --y100: #FFFCF6;
        --y200: #FFFAEE;
        --y300: #FFF3D4;
        --y400: #FFE6AA;
        --y500: #FFDA7F;
        --y600: #FFD56E;
        --y700: #FFCD54;
        --y800: #D99F2B;
        --y900: #AE7F22;
        --y1000: #684C14;

        /* orange */
        --o100: #FFF7F5;
        --o200: #FFF0EB;
        --o300: #FFD9CC;
        --o400: #FFB39A;
        --o500: #FF8E67;
        --o600: #FF7F53;
        --o700: #FF6835;
        --o800: #DE5A2E;
        --o900: #CC5229;
        --o1000: #80341A;

        /* green */
        --g100: #F8FAE5;
        --g200: #FAFCEE;
        --g300: #F3F7D3;
        --g400: #E8EFA8;
        --g500: #DCE77C;
        --g600: #D8E46B;
        --g700: #D1DF51;
        --g800: #B2BE45;
        --g900: #9DA73D;
        --g1000: #687028;

    /* tokens */
        /* text and icons */
        --text-color-primary: var(--n1000);
        --text-color-secondary: var(--n700);
        --text-color-inactive: var(--n600);

        font-size: 10px;
        --text-size-1: 1rem;
        --text-size-2: 1.2rem;
        --text-size-3: 1.4rem;
        --text-size-4: 1.6rem;
        --text-size-5: 2rem;
        --text-size-6: 2.8rem;
        --text-size-7: 3.2rem;

        --text-weight-light: 300;
        --text-weight-regular: 400;
        --text-weight-bold: 700;

        /* buttons */
            /* primary */
            --button-primary-active: var(--y700);
            --button-primary-hover: var(--y800);
            --button-primary-pressed: var(--y900);
            --button-primary-inactive: var(--n200);
            --button-primary-text: var(--text-primary);
}

[data-theme="dark"] {
    /* text and icons */
    --text-primary: var(--n100);
    --text-secondary: var(--n200);
    --text-inactive: var(--n300);

    /* buttons */
        /* primary */
        --button-primary-active: var(--y700);
        --button-primary-hover: var(--y600);
        --button-primary-pressed: var(--y100);
        --button-primary-inactive: var(--n700);
        --button-primary-text: var(--text-primary);
}