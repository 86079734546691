@import url(reset.css);
@import url(tokens.css);

/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #000000 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 0px;
    border: 5px solid #ffffff;
}

html {
    font-style: normal;
}

.announcements {
    background: var(--n1000);
    grid-row: 1 / 3;
    color: white;
    overflow-y: scroll;
    scrollbar-color: #3D3B36 #0D0A04;
}

  /* Chrome, Edge, and Safari */
.announcements::-webkit-scrollbar {
    width: 16px;
}

.announcements::-webkit-scrollbar-track {
    background: #0D0A04;
}

.announcements::-webkit-scrollbar-thumb {
    background-color: #3D3B36;
    border-radius: 0px;
    border: 5px solid #0D0A04;
}

.announcements > div > div {
    background: var(--n1000);
    padding-bottom: 8px !important;
}

.announcements .grid-wrapper, .resources .grid-wrapper {
    padding-top: 0;
}

.announcements > div > div, .resources .wrap {
    position: sticky;
    width: 100%;
    top: 0;
    padding: 24px 0;
}

.announcements .metadata {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    font-weight: var(--text-weight-light);
}

.announcements .metadata p::after{
    content: "\00B7";
    display: inline-block;
    margin-left: 8px;
    font-weight: var(--text-weight-light);
}

.announcements .metadata #iotd_title {
    font-weight: var(--text-weight-bold);
}

.announcements .metadata p:last-child:after {
    display: none;
}

.announcements .metadata img {
    width: 24px;
    border-radius: 50%;
}

.announcements li {
    padding: 24px 0;
    border-bottom: 1px solid var(--n800);
}

.bold {
    font-weight: var(--text-weight-bold);
}

body {
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-weight: var(--text-weight-regular);
    font-style: normal;
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    align-items: center;
}

body.login {
    margin: 0;
    justify-content: center;
    background: var(--y700);
}

body.home, body.home nav {
    background: var(--n1000);
}

body.home nav .events {
    background-image: url(../svgs/calendar-white.svg);
}

body.home nav .logout {
    background-image: url(../svgs/logout-white.svg);
}

body.home nav .office {
    background-image: url(../svgs/office-white.svg);
}

body.home nav .myspace {
    background-image: url(../svgs/user-white.svg);
}

body.home nav .funfive {
    background-image: url(../svgs/hand-white.svg);
}

body.home nav .wheel {
    background-image: url(../svgs/wheel-white.svg);
}

body.home nav .mobile a {
    color: var(--n500);
}

body.home nav .mobile .selected {
    color: var(--n100);
}

.brush {
    position: absolute;
}

.brush.top-left {
    top: -8px;
    left: -48px;
    rotate: -24.87deg;
}

.brush.bottom-right {
    bottom: 0;
    right: -64px;
    rotate: -29.91deg;
}

button {
    cursor: pointer;
    width: fit-content;
    display: inline-block;
    border: none;
    border-radius: 80px;
    padding: 20px 40px;
    color: var(--n1000);
    background: var(--y700);
    font-size: var(--text-size-3);
    font-weight: var(--text-weight-bold);
    font-family: 'Object Sans', 'Helvetica', sans-serif;
}

button.outline {
    padding: 14px 24px;
    background: none;
    border: 2px solid var(--n1000);
    font-weight: var(--text-weight-regular);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

button.outline img {
    display: none;
}

button.outline:hover {
    background: var(--n100);
}

button.outline.selected {
    background: var(--n200);
    padding: 4px 24px 4px 16px;
}

button.outline.selected img {
    display: block;
}

.card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 50vh;
    width: 40vw;
    background: var(--g700);
}

.card.countdown {
    background: var(--n1000);
    color: white;
    height: 100%;
    width: 55vw;
}

.card.countdown h2 {
 color: var(--y700);
}

.card.countdown .card-details {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 24px;
    padding: 0px;
}

.clicker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:transparent;
}

.countdown-info {
    padding: 24px;
}

.card.event {
    max-height: 500px;
}

.card.event:nth-of-type(odd) {
    margin-top: 100px;
}

.card.event:nth-of-type(even) {
    margin-top: -100px;
    background: var(--o700);
}

.card-details {
    padding: 24px;
    font-size: var(--text-size-4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-details .metadata {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.card-image {
    background-image: url('https://uploads-ssl.webflow.com/63483b0e2f4d8b3e51c2569b/6348484ea246c655cc17d687_Event%20Image.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.content {
    margin-left: 12vw;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.empty-container {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    gap: 40px;
    font-size: var(--text-size-5);
    color: var(--text-color-secondary);
    text-align: center;
    line-height: 160%;
    grid-column: 1 / 2;
    grid-row: 3 / 5;
}

.not100vh {
    height: auto;
    overflow-y: auto;
    display: block;
}

.funfive-wrapper {
    display: block;
    width: calc(100% - 12vw - 64px);
    height: auto;
    margin-top: 48px;
    margin-left: calc(12vw + 40px);
    font-size: var(--text-size-4);
}

.funfive-wrapper .flex-wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: 60% auto;
    grid-template-rows: min-content min-content min-content 1fr;
}

.funfive-wrapper .lines {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

#feed {
    grid-column: 1 / 2;
    grid-row: 3 / 5;
    margin-bottom: 80px;
}

.new-fives {
    grid-column: 2 / 3;
    grid-row: 1 / 4;
}

.new-fives-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 2 / 3;
    grid-row: 1 / 4;
    background-color: var(--g700);
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    position: relative;
}

.new-fives-confirm .hands {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
}

.new-fives-confirm .hand {
    width: 50%;
    max-width: 200px;
}

#hand-1 {
    z-index: 2;
}

.new-fives-confirm #smack {
    width: 100px;
    margin-bottom: -25px;
    z-index: 1;
    margin-top: 80px;
}

#copy {
    z-index: 2;
}

.new-fives-confirm .clouds {
    position: absolute;
    width: 100%;
    height: 100%;
}


.top-clouds, .bottom-clouds {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.top-clouds {
    margin-top: 40px;
}

.bottom-clouds {
    margin-top: 100px;
}

#topleft {
    width: 20%;
    max-width: 120px;
}

#topright, #bottomleft {
    width: 40%;
    max-width: 200px;
}

#topright {
    margin-top: 20px;
    max-width: 160px;
}

#bottomright {
    z-index: 2;
    width: 25%;
    max-width: 160px;
}

.funfive-wrapper h1 {
    position: static;
    margin-bottom: 24px;
    font-size: var(--text-size-6);
    font-weight: var(--text-weight-bold);
    text-transform: none;
}

.funfive-wrapper .lines {
    filter: invert(1);
    width: 100%;
    margin-top: 0;
}

#funfive-quill {
    display: block;
    width: 100%;
    margin: 24px 0 0;
    border: 2px solid var(--n1000);
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
    resize: vertical;
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-weight: var(--text-weight-regular);
    font-style: normal;
    font-size: var(--text-size-3);
}

.mention-container {
    background-color: #ffffff;
    padding: 20px;
    border: 2px solid var(--n1000);
    border-radius: 4px;
    max-height: 250px;
    overflow: scroll;
}

.mention-list-item {
    margin-bottom: 20px;
    cursor: pointer;
}

.filters {
    display: flex;
    gap: 16px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

#feed .date-block {
    padding: 16px 0 0;
}

#feed .date-block.last {
    border-bottom: 1px solid var(--n300);
    padding-bottom: 32px;
}

#feed .send-date {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: var(--text-size-3);
    color: var(--text-color-secondary);
    text-transform: uppercase;
    margin-top: 8px;
}

#feed li {
    margin-top: 32px;
}

#feed li:first-of-type {
    margin-top: 16px;
}

#feed li .metadata {
    margin-bottom: 10px;
}

#feed #message {
    line-height: 160%;
}

#feed li span {
    font-weight: var(--text-weight-bold);
}

.facestack {
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 16px;
}

.facestacksub {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 52px;
    height: 100%;
    margin-left: -16px;
}

.facestack img {
    width: 52px !important;
    height: 52px !important;
    border: 2px solid white;
    border-radius: 50%;
    object-fit: cover;
}

.facestack .facestacksub img {
    margin-left: -16px;
}

.ff-image {
    z-index: 1;
    opacity: 0;
    margin-left: -16px;
    margin-right: -16px;
    border: none !important;
    transform: scale(0.8) !important;
    transform-origin: center;
}

.new-fives {
    display: flex;
    flex-flow: column;
    position: sticky;
    top: 24px;
    background-color: var(--g700);
    padding: 40px;
}

.new-fives label:first-child {
    font-size: var(--text-size-6);
}

.new-fives label:nth-of-type(2) {
    font-size: var(--text-size-3);
    font-weight: var(--text-weight-bold);
    margin-bottom: 8px;
}

.new-fives textarea {
    display: block;
    width: 100%;
    margin: 24px 0 0;
    border: 2px solid var(--n1000);
    border-radius: 4px;
    background-color: transparent;
    padding: 16px;
    box-sizing: border-box;
    resize: vertical;
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-weight: var(--text-weight-regular);
    font-style: normal;
    font-size: var(--text-size-3);
}

.new-fives textarea::placeholder {
    color: var(--text-color-secondary);
}

select {
    color: var(--text-color-primary);
    display: block;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid var(--n1000);
    background: transparent;
    background-image: url(../svgs/chevron-down.svg);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 16px);
    background-position-y: 50%;
    background-size: 24px;
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-weight: var(--text-weight-regular);
    font-style: normal;
    font-size: var(--text-size-3);
}

option {
    width: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.filters select {
    flex-grow: 1;
}

.new-fives button {
    background-color: var(--n1000);
    color: white;
    margin-top: 24px;
    width: 100%;
}

.new-fives button:hover {
    background: var(--n900);
}

.event-auth {
    margin: 0 auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: var(--text-size-3);
    line-height: 130%;
}

.event-auth button {
    margin-top: 40px;
}

.event-auth .close {
    position: absolute;
    top: 40px;
    right: 40px;
    background: white;
    padding: 12px 12px 8px;
}

.event-auth .logo {
    margin-bottom: 20px;
    width: 160px;
}

.event-auth main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    background: white;
    padding: 40px;
}

.event-auth h1 {
    font-size: var(--text-size-4);
    font-weight: var(--text-weight-bold);
    text-transform: none;
    display: inline-block;
    position: static;
    z-index: 0;
    margin-bottom: 16px;
}

.events-wrap {
    column-gap: 24px;
    padding-right: 12vw;
    max-height: 800px;
}

.fixed-wrapper {
    position: absolute;
    top: 0;
}

.funsizer, .funsizer > div {
    background: var(--y700);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 24px;
}

.funsizer .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.funsizer .name {
    font-size: var(--text-size-5);
    font-weight: var(--text-weight-bold);
    margin-bottom: 6px;
}

.funsizer .wrap {
    text-decoration: none;
    color: var(--n1000);
    background: white;
    border-radius: 80px;
    padding: 16px 24px;
    font-size: var(--text-size-3);
    font-weight: var(--text-weight-bold);
}

.funsizer a:hover {
    background: var(--n100);
}

.funsizer .wrap img {
    height: 20px;
}

.funsizer .wrap p {
    margin-bottom: -2px;
}

.funsizer .year {
    font-weight: var(--text-weight-bold);
}

.grid-title {
    font-size: var(--text-size-5);
}

.grid-wrapper {
    padding: 24px 8px 24px 24px;
    position: relative;
}

h1 {
    font-size: var(--text-size-7);
    text-transform: uppercase;
    position: fixed;
    z-index: -1;
}

h2 {
    font-size: var(--text-size-7);
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 24px;
}

.hidden {
    opacity: 0;
}

.home .content {
    width: 100%;
    margin-right: 12vw;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home .metadata {
    text-align: center;
    max-width: 85%;
}

.home .metadata #iotd_subtitle {
    font-size: var(--text-size-3);
    font-weight: var(--text-weight-light);
}

.home .metadata #iotd_title { 
    margin: 8px 0;
    font-size: var(--text-size-4);
    font-weight: var(--text-weight-bold);
    column-gap: 4px;
    text-align: center;
}

.home .metadata .wrap img {
    height: 20px;
}

.info {
    padding: 0 24px;
}

img {
    max-width: 100%;
    height: auto;
}

input[type="search"] {
    appearance: none;
    border: none;
    background: none;
    color: var(--n1000);
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-size: var(--text-size-5);
    font-weight: var(--text-weight-regular);
    caret-color: var(--y700);
    width: 100%;
}

input[type="search"]:focus-visible {
    outline: none;
}

input[type="search"]::placeholder {
    color: var(--n800);
    opacity: .5;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
}

.lines {
    background: url(../images/lines.png);
    height: 8px;
    margin-top: 24px;
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
}

.loader-container-inline {
    position: relative;
    display: none;
}

.loader {
    background-color: var(--n1000);
    border-radius: 10px;
    width: 20px;
    height: 20px;
}

.light {
    background-color: #fff;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo img {
    height: 32px;
    width: auto;
    max-width: calc(100% - 16px);
}
.login main {
    display: grid;
    grid-template-columns: minmax(0, 55%) minmax(0, 1fr);
    grid-template-rows: auto;
    width: calc(100vw - 200px);
    height: calc(100vh - 200px);
    background: var(--n1000);
    font-size: var(--text-size-4);
    line-height: 120%;
}

.login main > div:first-child {
    background: url(../images/login-fallback.png);
    background-position: 50%;
    background-size: cover;
}

.map {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    border-top: 2px solid var(--n1000);
    border-bottom: 2px solid var(--n1000);
    height: calc(100% - 4px) !important;
    display: grid;
    grid-template-columns: 45% 1fr;
    grid-template-rows: minmax(0, 1fr);
    justify-items: center;
    align-items: center;
}

.map img {
    justify-self: flex-end;
    margin-right: 40px;
    width: calc(95% - 40px);
    max-height: calc(95% - 40px);
}

.map ul {
    width: 85%;
}

.map li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    padding: 20px 8px;
    border-bottom: 1px solid var(--n1000);
}

.map li:first-child {
    padding-top: 10px;
}

.map li p:nth-child(2) {
    font-weight: var(--text-weight-bold);
}

.modal-wrapper {
    display: none;
    z-index: 999;
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: hsla(0, 0%, 100%, .75);
}

.modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: white;
    padding: 40px;
    width: 45%;
    font-size: var(--text-size-4);
    line-height: 160%;
    border-radius: 8px;
    box-shadow: 0px 26px 80px rgba(0, 0, 0, 0.1), 0px 13.1625px 34.875px rgba(0, 0, 0, 0.0675), 0px 5.2px 13px rgba(0, 0, 0, 0.05), 0px 1.1375px 4.625px rgba(0, 0, 0, 0.0325);
}

.modal-close {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 44px;
    height: 44px;
}

.modal-close img {
    width: 24px;
    height: 24px;
}

.modal-open {
    display: flex;
}

.myspace-wrapper {
    margin-right: 6vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    font-size: var(--text-size-3);
}

.myspace-wrapper > div {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
}

.myspace-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 8px;
}

.myspace-user-info:hover {
    background-color: var(--y400) !important;
}

#myspace-user-edit {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

#myspace-user-edit button {
    margin-top: 16px;
}

#myspace-user-edit input[type="text"] {
    appearance: none;
    border: solid 1px var(--y700);
    background: none;
    color: var(--n1000);
    font-family: 'Object Sans', 'Helvetica', sans-serif;
    font-size: var(--text-size-4);
    font-weight: var(--text-weight-regular);
    caret-color: var(--y700);
    padding: 8px;
    width: 100%;
    text-align: center;
}

#myspace-current-image {
    width: 112px;
    height: 112px;
    border-radius: 56px;
    border: solid 1px var(--y700);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.MMFOOD, .mobile-close {
    display: none;
}

nav {
    width: 12vw;
    height: calc(100vh - 96px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    align-items: center;
    align-content: center;
    background: white;
}

nav ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
}

nav ul li a {
    box-sizing: border-box;
    display: inline-block;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: center;
}

nav ul li a:hover {
    border: 1px solid var(--n200);
}

body.home nav ul li a:hover {
    border-color: var(--n500);
}

nav .selected {
    background-color: var(--y700);
}

nav .selected:hover {
    background-color: var(--y800);
    border: none;
}

nav .home {
    background-image: url(../svgs/home.svg);
}

nav .events {
    background-image: url(../svgs/calendar.svg);
}

nav .funfive {
    background-image: url(../svgs/hand.svg);
}

nav .wheel {
    background-image: url(../svgs/wheel.svg);
}

nav .logout {
    background-image: url(../svgs/logout.svg);
    height: 44px;
    width: 44px;
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: center;
}

nav .mobile {
    display: none;
}

nav .office {
    background-image: url(../svgs/office.svg);
}

nav .myspace {
    background-image: url(../svgs/user.svg);
}

nav ul img {
    height: 28px;
    width: 28px;
}

nav #webgl {
    display: none;
    pointer-events: none;
    z-index: -100;
}

.profile-photo {
    border-radius: 50%;
    width: 96px !important;    
    height: 96px !important;
    object-fit: cover;
}

.react-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-top: 6px;
}

.react-new {
    background: none;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-new img {
    pointer-events: none;
}

.react-new:hover img {
    filter: brightness(.6);
}

.react-pill {
    font-size: var(--text-size-3);
    font-weight: var(--text-weight-regular);
    padding: 7px 12px 7px 11px;
    background: var(--n100);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    position: relative;
}

.react-pill.selected {
    background: #1297fc;
    color: white;
}

.react-pill span.emoji {
    font-size: var(--text-size-4);
}

.react-pill span, p {
    pointer-events: none;
}

.react-pill:hover {
    background: var(--n200);
}

.react-pill.selected:hover {
    background: #0874c6;
}

.react-pill:hover .react-list {
    display: inline;
}

.react-pill img {
    width: 16px;
    height: 16px;
}

.react-list {
    display: none;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    color: white;
    border-radius: 4px;
    padding: 8px;
    width: 120px;
    margin-bottom: 8px;
    z-index: 2;
}

.react-list::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid black;
    border-color: black transparent transparent transparent;
    
}

#reaction-menu {
    z-index: 4;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    background: white;
    border: 2px solid var(--n100);
    border-radius: 6px;
    padding: 6px;
}

#reaction-menu button {
    background: none;
    border-radius: 4px;
    padding: 0;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-size-5);
}

#reaction-menu button:hover {
    background: var(--n100);
}

#reaction-menu img {
    width: 24px;
    height: 24px;
}

.resources {
    background: var(--o700);
    overflow-y: scroll;
    position: relative;
}

.resources .fixed-wrapper {
    width: calc(100% - 48px);
}

.resources ul li {
    border-bottom: 1px solid var(--o900);
}

.resources ul li a {
    padding: 16px 0 16px 8px;
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 130%;
}

.resources ul li a img {
    opacity: 0;
    padding-right: 4px;
}

.resources ul li a:hover {
    background: var(--o800);
}

.resources ul li a:hover img {
    opacity: 1;
}

.resources ul li a:active {
    background: var(--o900);
}

.resources .wrap {
    background: var(--o700);
    display: flex;
    justify-content: space-between;
}

.resources {
    scrollbar-width: auto;
    scrollbar-color: #CC5229 #FF6835;
}

  /* Chrome, Edge, and Safari */
.resources::-webkit-scrollbar {
    width: 16px;
}

.resources::-webkit-scrollbar-track {
    background: #FF6835;
}

.resources::-webkit-scrollbar-thumb {
    background-color: #CC5229;
    border-radius: 0px;
    border: 5px solid #FF6835;
}

.search-wrapper {
    margin: 24px 0;
    display: none;
    flex-direction: row;
    align-items: center;
}

.search-wrapper img {
    width: 32px;
}

.slack-message {
    margin-top: 16px;
    line-height: 130%;
    letter-spacing: .1px;
    color: var(--n400);
}

.slack-message a {
    text-decoration: none;
    color: var(--y700);
}

.slack-message .slack-cmd {
    color: var(--g700);
}

.soon section {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: var(--text-size-5);
    text-transform: uppercase;
    font-weight: var(--text-weight-bold);
    text-align: center;
}

.soon div:not(.soon main div) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 325px;
    height: 150px;
}

.soon #top p {
    margin-top: -40px;
}

.soon #bottom p {
    margin-top: 40px;
    font-style: italic;
    font-weight: 570;
}

.soon div img {
    position: absolute;
    z-index: -1;
}

.soon main {
    display: flex;
    gap: 24px;
}

.soon main div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 225px;
}

.soon main p {
    font-size: 16rem;
    letter-spacing: -4px;
    color: white;
    -webkit-text-stroke: 4px var(--n1000);
    text-shadow: 4px 12px 0px var(--n1000);
    font-weight: var(--text-weight-regular);
}

.soon main span {
    font-size: var(--text-size-3);
    background: var(--y700);
    padding: 8px 12px;
    border-radius: 20px;
}

sup {
    vertical-align: super;
    font-size: var(--text-size-3);
    font-style: normal;
    margin-left: 6px;
}

.splash-wrapper {
    position: relative;
    margin: 32px 64px;
    max-height: 80%;
}

.splash-image {
    max-height: 100%;
    display: block;
}

.thanos {
    display: none !important;
}

.unkown-person-container {
    width: 100%;
    height: 100%;
}

#unknown-person {
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 48px 48px 56px;
}

#unknown-person .logo {
    mix-blend-mode: exclusion;
    margin-bottom: 32px;
}

#unknown-person .logo {
    width: 60%;
}

#unknown-person p {
    max-width: 360px;
}

.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}

.wrap p {
    margin-bottom: -4px;
}

#webgl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.webgl-card {
    position: relative !important;
}

.webgl-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    perspective: 500;
}

.webgl-event {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
}

.webgl-svg {
    position: absolute;
    width: 110%;
    height: 110%;
}

.webgl-svg-large {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.4);
}

.webgl-svg-rel {
    position: relative;
}

.year {
    margin-top: 2px;
}

::selection {
    background: var(--y700);
    color: var(--n1000);
  }

::-moz-selection {
    background: var(--y700);
    color: var(--n1000);
}

/* API, Admin, and Debug Stuff */
.integration {
    padding: 20px;
    margin: 20px;
    border: solid 10px #ffffff;
    color: #111111;
}

#g_id_signin {
    margin-top: 48px;
}

.links {
    margin-top: 20px;
}

#data_links {
    display: flex;
    flex-direction: column;
}

#data_iotd_img {
    width: 250px
}

.int_title {
    font-weight: 600;
    margin-top: 20px;
}

.int_message {
    margin-top: 10px
}

.admin_head {
    width: 100%;
    background-color: #111111;
    color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    font-size: large;
    z-index: 1;
}

.admin_head:first-child {
    margin-left: 20px;
}

.admin_head div:last-child {
    margin-right: 20px;
}

.int_btn {
    border: solid #ffffff 1px;
    padding: 5px;
    margin-right: 5px;
    color: #ffffff;
    cursor:pointer;
}

.int_btn:hover {
    background-color: #ffffff;
    color: #111111;
}

.int_selected {
    border: none;
    pointer-events: none;
    font-weight: 600;
}

.int_btn:first-child {
    margin-left: 10px;
}

.int_admin_body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: scroll;
    flex-direction: column;
}

.int_admin_body select {
    all: revert;
}

.int_admin_body option {
    all: revert;
}


#admin_data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px 0 0 10px;
}

.peep {
    position: relative;
    padding: 25px;
    margin: 10px 10px 0 0;
    border: solid #111111 1px;
    cursor:pointer;
    display: flex;
    flex-direction: column;
}

.peep img {
    width: 150px;
    margin-bottom: 10px;
    pointer-events: none;
}

.peep span {
    pointer-events: none;
}

.spot {
    position: relative;
    padding: 25px;
    margin: 10px 10px 0 0;
    border: solid #111111 1px;
    display: flex;
    flex-direction: column;
}

.spot_hot {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.int_data_edit {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.int_data_edit_btns {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.int_hidden {
    display: none;
}

.int_modal_shim {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #00000044;
    z-index: 2;
    display: none;
}

.int_modal {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border: solid #111111 1px;
    padding: 25px;
    margin: 25px;
}

@media all and (min--moz-device-pixel-ratio:0) {
    .splash-wrapper {
        max-width: 80vh;
    }

    .grid-wrapper {
        padding-right: 24px;
    }
}


@media screen and (min-width: 2000px) {
    .myspace-wrapper {
        column-gap: 48px;
        row-gap: 60px;
    }
}

@media screen and (min-width: 1400px) {

    .announcements > div > div {
        padding: 40px 0 16px !important;
    }

    .card {
        max-height: 40vh;
        max-width: 700px;
    }

    .card.countdown {
        max-height: max-content;
        max-width: 1000px;
    }

    .funsizer .name {
        font-size: var(--text-size-6);
    }
    
    .funsizer .wrap :not(.funsizer a p) {
        font-size: var(--text-size-4);
    }

    .grid-title {
        font-size: var(--text-size-6);
    }

    .grid-wrapper {
        padding: 40px 24px 40px 40px;
    }

    .info {
        padding: 0 40px;
        line-height: 130%;
    }

    input[type="search"] {
        font-size: var(--text-size-6);
    }

    .myspace-wrapper {
        font-size: var(--text-size-4);
    }

    .resources ul li a {
        padding: 24px 0 24px 8px
    }
    
    .resources .wrap {
        padding: 40px 0 24px;
    }

    .search-wrapper {
        margin: 40px 0 24px;
    }

    .slack-message {
        line-height: 160%;
    }

}

@media screen and (max-width: 1200px) {
    .login main {
        grid-template-columns: minmax(0, 45%) minmax(0, 1fr);
        width: calc(100vw - 100px);
        height: calc(100vh - 100px);
    }

    .map {
        grid-template-columns: 80%;
        justify-content: center;
    }

    .map img {
        display: none;
    }

    .metadata p:nth-child(2)::after {
        content: "";
    }

    .metadata p:nth-child(3) {
        display: none;
    }
}

@media screen and (max-width: 1070px) {
    .filters {
        flex-flow: wrap;
    }
    
    .filters select {
        width: 100%;
    }

    .filters button {
        flex-grow: 1;
    }
}

@media screen and (max-width: 1030px) {
    .soon main p {
        font-size: 12rem;
        text-shadow: 4px 8px 0px var(--n1000);
    }
}

@media screen and (max-width: 940px) {

    .funfive-wrapper .flex-wrapper {
        grid-template-columns: 50% auto;
    }

    .card {
        width: 50vw;
    }

    .card.countdown {
        width: 70vw;
    }

    .card.event:nth-of-type(odd) {
        margin-top: -7vh;
    }
    
    .card.event:nth-of-type(even) {
        margin-top: 7vh;
    }
}

@media screen and (max-width: 800px) {
    .funfive-wrapper .flex-wrapper {
        grid-template-columns: 1fr;
        gap: 36px;
    }

    .filters {
        grid-row: 3 / 4;
    }

    .new-fives, .new-fives-confirm {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        position: static;
    }

    .new-fives-confirm {
        position: relative;
    }

    #feed, .empty-container {
        grid-row: 4 / 5;
    }
}

@media screen and (max-width: 770px) {

    .soon {
        font-size: var(--text-size-4);
    }

    .soon div:not(.soon main div) {
        width: 250px;
        height: 110px;
    }

    .soon img {
        width: 250px;
    }

    .soon main p {
        font-size: 10rem;
        text-shadow: 4px 4px 0px var(--n1000);
    }

    .soon #top p {
        margin-top: -25px;
    }

    .soon #bottom p {
        margin-top: 25px;
    }
}

@media screen and (max-width: 650px) {

    .announcements {
        grid-row-start: 2;
    }

    .big-number {
        padding-top: 40px;
    }

    .brush {
        scale: 80%;
    }
    
    body:not(.event-auth, .login) {
        overflow-y: auto;
        height: auto;
        width: 100%;
        flex-direction: column;
    }

    .content {
        flex-direction: column;
        height: inherit;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

    .content.myspace-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        margin: 94px auto 12vh;
    }

    .card {
        grid-template-columns: 1fr;
        width: 100%;
        min-height: 40vh;
    }

    .card-details {
        gap: 24px;
    }

    .card.countdown {
        width: 100%;
    }

    .card-image {
        display: none;
    }

    .card.event:nth-of-type(odd) {
        margin-top: initial;
    }
    
    .card.event:nth-of-type(even) {
        margin-top: initial;
    }

    .events-wrap {
        margin-top: 94px;
        padding-right: initial;
        row-gap: 24px;
        margin-bottom: 12vh;
        max-height: min-content;
    }

    .fixed-wrapper {
        position: initial;
        width: 100%;
    }

    .funsizer .profile-photo {
        margin-top: 24px;
        width: 112 !important;
        height: 112px !important;
    }

    .funsizer > div {
        margin: 24px;
    }

    .funfive-wrapper {
        margin-top: 0;
    }

    .funfive-wrapper h1 {
        padding-top: 120px;
    }

    .funfive-wrapper .new-fives-confirm h1 {
        position: absolute;
        bottom: 30;
    }

    h1 {
        position: initial;
    }

    .home .content {
        margin-right: inherit;
        height: auto;
        padding-top: calc(94px + 8vh);
    }

    .login main {
        width: 100vw;
        height: 100vh;
        grid-template-columns: auto;
        grid-template-rows: minmax(0, 35%) minmax(0, 1fr);
    }

    .map {
        grid-column: auto;
        grid-row: auto;
        grid-template-columns: 1fr;
    }

    .map ul {
        margin: 24px auto;
        width: 100%;
    }
    
    .map li:last-child {
        border-bottom: none;
        padding-bottom: 8px;
    }

    .MMFOOD {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
    }

    .mobile-menu {
        flex-direction: column;
        position: fixed;
        width: 100vw;
        height: 100vh;
        padding: 0;
        margin: 0;
        place-content: center;
        gap: 40px;
    }

    .mobile-menu ul {
        padding: 0;
        display: flex;
        gap: 40px;
    }

    .mobile-menu li a, .mobile-menu .logout {
        width: 64px;
        height: 64px;
    }

    .mobile-menu .logo, .mobile-menu .MMFOOD {
        display: none;
    }

    .mobile-menu #btn_logout, .mobile-menu .mobile-close {
        display: inline-block;
    }

    .mobile-menu .mobile-close {
        position: absolute;
        top: 24px;
        right: 24px;
        padding: 10px;
        width: 24px;
        height: 24px;
    }

    .mobile-menu .home:not(.selected) {
        background-image: url(../svgs/home-white.svg);
    }

    .mobile-menu .events:not(.selected) {
        background-image: url(../svgs/calendar-white.svg);
    }

    .mobile-menu .myspace:not(.selected) {
        background-image: url(../svgs/user-white.svg);
    }

    .mobile-menu .funfive:not(.selected) {
        background-image: url(../svgs/hand-white.svg);
    }

    .mobile-menu .wheel:not(.selected) {
        background-image: url(../svgs/wheel-white.svg);
    }

    .mobile-menu .logout {
        background-image: url(../svgs/logout-white.svg);
    }

    nav {
        flex-direction: row;
        width: calc(100% - 40px);
        margin-right: 16px;
        height: auto;
        padding: 24px 0px;
        align-self: flex-end;
    }

    nav .logo {
        width: 80px;
    }

    nav ul, #btn_logout {
        display: none;
    }

    nav #webgl {
        display: block;
    }

    .not100vh {
        display: flex;
    }

    .logo img {
        max-width: 100%;
    }

    .soon {
        height: 100vh !important;
        overflow-y: hidden !important;
    }

    .soon main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 0px;
        width: 80%;
    }

    .soon main div {
        width: 100%;
    }

    #unknown-person {
        margin-bottom: 80px;
    }

}

@media screen and (max-height: 900px) {
    .profile-photo {
        width: 80px;
    }
}

@media screen and (max-height: 650px) {
    .profile-photo {
        display: none;
    }
}